import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Recruiter = () => {
  // State to manage the class of the div
  const [divClass, setDivClass] = useState(false);

  // Function to handle button click and add the new class
  const handleButtonClick = () => {
    setDivClass(true);
  };

  // State to manage the class of the div
  const [divClass2, setDivClass2] = useState(false);

  // Function to handle button click and add the new class
  const handleButtonClick2 = () => {
    setDivClass2(true);
  };

  return (
    <div>
      <div className="page-bnr">
        <h1 className="fw-bold mb-0 text-center">Recruiter</h1>
      </div>
      <section className="about-us-wrapper py-5">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 text-justify">
              <div className="about-us-content text-justify py-4">
                <p className="mb-3">
                  <strong>Create and Elevate Your Profile:</strong> Craft a
                  comprehensive profile and upload your resume to showcase your
                  professional expertise effectively.
                </p>
                <p className="mb-3">
                  <strong>Expand and Strengthen Your Network:</strong> Broaden
                  and consolidate your candidate connections to enhance your
                  reach and opportunities.
                </p>
                <p className="mb-3">
                  <strong>Tailored Candidate Connections:</strong> Connect with
                  candidates precisely aligned with your industry and job
                  specifications, ensuring a perfect match for your
                  requirements.
                </p>
                <p className="mb-3">
                  <strong>Real-Time Alerts:</strong> Receive instant alerts when
                  candidates matching your criteria enter the job market.
                </p>
                <p className="mb-3">
                  <strong>Skill Updates:</strong> Stay informed when candidates
                  update their skills, ensuring you're always in the loop.
                </p>
                <p className="mb-3">
                  <strong>Efficient Organization:</strong> Manage candidates
                  effectively by utilizing candidate tags for streamlined
                  organization.
                </p>
                <p className="mb-3">
                  <strong>Seamless Communication:</strong> Receive candidate
                  resumes directly through our chat feature, enhancing your
                  engagement.
                </p>
                <p className="mb-3">
                  <strong>Enhanced Management:</strong> Easily oversee job
                  postings and provide regular updates to candidates through our
                  Application Tracker feature.
                </p>
                <p className="mb-3">
                  <strong>Free Trial:</strong> Discover the full potential of
                  Opportunity Plate App with a <b>14-days free trial</b> for
                  both Basic and Premium recruiter subscriptions. Enjoy
                  unrestricted access to advanced features, enhancing your
                  recruitment capabilities. After the trial period, choose from
                  the plans below to align with your specific recruiting needs.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="about-us-image">
                <img
                  style={{
                    height: "770px",
                    width: "100% !important",
                  }}
                  src={
                    process.env.PUBLIC_URL + "/assets/images/Old_recruiter.jpg"
                  }
                  className="img-fluid rounded-4 shadow object-fit-cover"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pricing pb-5">
        <div className="container-fluid pricingTable">
          <h2 className="text-center mb-5">Subscription Plans</h2>
          <div className="container">
            <div className="row justify-content-center monthlyPriceList animated">
              <div className="col-lg-5 col-12 d-flex">
                <div className="inner holder">
                  <div>
                    <div className="hdng">
                      <span>
                        <i className="fa fa-tag" aria-hidden="true"></i>
                      </span>
                      <p>Basic Plan </p>
                    </div>
                    <div className="info mt-3">
                      <ul>
                        <li>
                          Subscription plans: $6.99 monthly, $24.99 for 3
                          months, $49.99 for 6 months and $81.99 for 12 months.
                        </li>
                        <li>Post each job at $11.99.</li>
                        <li>Create Profile and upload resume.</li>
                        <span className={divClass ? "d-block" : "d-none"}>
                          <li className="">
                            Expand and consolidate your candidate network.
                          </li>
                          <li className="">
                            Connect with candidates tailored to your industry
                            and job requirements.
                          </li>
                          <li className="">
                            Get alerts for candidate availability.
                          </li>
                          <li className="">
                            Receive skill updates from candidates.
                          </li>
                          <li className="">
                            Build a reliable network beyond job shifts.
                          </li>
                        </span>
                      </ul>
                      <button
                        id="Know_More"
                        onClick={handleButtonClick}
                        className={
                          `btn-custom mt-4 ` + (divClass ? "d-none" : "d-block")
                        }
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      className={
                        `btn-custom mt-3 w-100 Subscribe_Now ` +
                        (divClass ? "d-block" : "d-none")
                      }
                      data-bs-toggle="modal"
                      data-bs-target="#planModal"
                    >
                      Download Now
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-12 d-flex">
                <div className="inner holder">
                  <div>
                    <div className="hdng">
                      <span>
                        <i className="fa fa-rocket" aria-hidden="true"></i>
                      </span>
                      <p>Premium Plan</p>
                    </div>
                    <div className="info mt-3">
                      <ul>
                        <li>
                          Subscription plans: $13.99 monthly, $49.99 for 3
                          months, $99.99 for 6 months and $149.99 for 12 months.
                        </li>
                        <li>1 free job posting per month</li>
                        <li>Add each additional job posting for only $5.99.</li>
                        <span className={divClass2 ? "d-block" : "d-none"}>
                          <li className="">
                            Create Profile and upload resume.
                          </li>
                          <li className="">
                            Expand and consolidate your candidate network.
                          </li>
                          <li className="">
                            Connect with candidates tailored to your industry
                            and job requirements.
                          </li>
                          <li className="">
                            Get alerts for candidate availability.
                          </li>
                          <li className="">
                            Receive skill updates from candidates.
                          </li>
                          <li className="">
                            Directly message and chat with candidates.
                          </li>
                          <li className="">
                            Receive reminders about your job postings
                          </li>
                          <li className="">
                            Receive candidate resumes via chat.
                          </li>
                          <li className="">
                            Keep track of candidate job interests and provide
                            regular updates.
                          </li>
                          <li className="">
                            Get alerts &amp; reminders for your active job
                            postings.
                          </li>
                          <li className="">
                            Give feedback to candidates on your open positions
                            through application tracker.
                          </li>
                        </span>
                      </ul>
                      <button
                        id="Know_More"
                        onClick={handleButtonClick2}
                        className={
                          `btn-custom mt-4 ` +
                          (divClass2 ? "d-none" : "d-block")
                        }
                      >
                        Read More
                      </button>
                    </div>
                  </div>
                  <div className="text-center">
                    <button
                      className={
                        `btn-custom mt-3 w-100 Subscribe_Now ` +
                        (divClass2 ? "d-block" : "d-none")
                      }
                      data-bs-toggle="modal"
                      data-bs-target="#planModal"
                    >
                      Download Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*<!-- Modal --> */}
      <div
        className="modal fade"
        id="planModal"
        tabindex="-1"
        aria-labelledby="planModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="planModalLabel">
                Download Now
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-sm-7 col-md-7 col-lg-8 pb-4 position-relative d-flex align-items-center mx-auto text-center">
                  <div className="slider-content">
                    <h1>Your Network! Your Opportunities!</h1>
                    <p className="text-center">
                      An easy-to-use network connection app for recruiters and
                      candidates!
                    </p>
                    <ul className="d-flex justify-content-center gap-15">
                      <li>
                        <NavLink>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/app-store.png"
                            }
                            className="img-fluid"
                            alt=""
                          />
                        </NavLink>
                      </li>
                      <li>
                        <NavLink>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/google-play.png"
                            }
                            className="img-fluid"
                            alt=""
                          />
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Recruiter;
