import React from 'react'
import { NavLink } from 'react-router-dom'

const Home = () => {
	return (
		<div>
			<section className="slider-wrapper">
				<div className="container">
					<div className="row">
						<div className="col-sm-7 col-md-7 col-lg-8 position-relative d-flex align-items-center">
							<div className="slider-content">
								<h1>Your Network! Your Opportunities!</h1>
								<p className="text-start">An easy-to-use network connection app for recruiters and candidates!</p>
								
								<ul className="d-flex justify-content-start gap-15">
								{/* <h2 className="coming-soon" style={{}}>Coming Soon</h2> */}
									<li>
										{/* <NavLink data-bs-toggle="modal" data-bs-target="#appModal">
											<img src={process.env.PUBLIC_URL + "/assets/images/app-store.png"} className="img-fluid" alt="" />
										</NavLink> */}
										<a href='https://apps.apple.com/us/app/opportunity-plate/id6451085309' target='_blank'>
											<img src={process.env.PUBLIC_URL + "/assets/images/app-store.png"} className="img-fluid" alt="" />
										</a>
									</li>
									<li>
										{/* <NavLink data-bs-toggle="modal" data-bs-target="#appModal">
											<img src={process.env.PUBLIC_URL + "/assets/images/google-play.png"} className="img-fluid" alt="" />
										</NavLink> */}
										<a href='https://play.google.com/store/apps/details?id=com.oppotunity.app.opportunity' target='_blank'>
											<img src={process.env.PUBLIC_URL + "/assets/images/google-play.png"} className="img-fluid" alt="" />
										</a>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-sm-5 col-md-5 col-lg-4 position-relative">
							<div className="slider-image">
								<img src={process.env.PUBLIC_URL + "/assets/images/mobile-image.png"} className="img-fluid" alt="" />
							</div>
						</div>
					</div>
				</div>
			</section>



			<section className="features-wrpper pt-5 pb-3">
				<div className="container">
					<h2>Opportunity Plate Features</h2>
					<div className="row">
						<div className="col-6 col-sm-6 col-md-6 col-lg-3">
							<div className="feature-item">
								<span><img src={process.env.PUBLIC_URL + "/assets/images/easy-to-use-icon.png"} className="img-fluid" alt="" /></span>
								<h3>Easy to use</h3>
							</div>
						</div>
						<div className="col-6 col-sm-6 col-md-6 col-lg-3">
							<div className="feature-item">
								<span><img src={process.env.PUBLIC_URL + "/assets/images/interactive-icon.png"} className="img-fluid" alt="" /></span>
								<h3>Interactive</h3>
							</div>
						</div>
						<div className="col-6 col-sm-6 col-md-6 col-lg-3">
							<div className="feature-item">
								<span><img src={process.env.PUBLIC_URL + "/assets/images/time-updates-icon.png"} className="img-fluid" alt="" /></span>
								<h3>Real -Time updates</h3>
							</div>
						</div>
						<div className="col-6 col-sm-6 col-md-6 col-lg-3">
							<div className="feature-item">
								<span><img src={process.env.PUBLIC_URL + "/assets/images/consolidated-icon.png"} className="img-fluid" alt="" /></span>
								<h3>Consolidated</h3>
							</div>
						</div>
					</div>
				</div>
			</section>


			<section className="advantages-wrpper pb-5">
				<div className="container">
					<h2 className="text-center">Opportunity Plate Advantages</h2>
					<div className="row">
						<div className="col-sm-6 col-md-6 col-lg-6">
							<div className="advantages-item">
								<div className="advantages-image">
									<img src={process.env.PUBLIC_URL + "/assets/images/image-01.jpg"} className="img-fluid" alt="" />
									<span className="sub-titel">
										Recuiters
									</span>
								</div>
								<div className="advantages-content">
									<ul>
										<li>Re-deploy and stay connected with candidates in your network</li>
										<li>Get notifications from candidates when they are “Back in The Job Market”.</li>
									</ul>
									<NavLink to="/Recruiters" className="btn-custom knowMore">More</NavLink>
								</div>
							</div>
						</div>
						<div className="col-sm-6 col-md-6 col-lg-6">
							<div className="advantages-item">
								<div className="advantages-image">
									<img src={process.env.PUBLIC_URL + "/assets/images/image-02.jpg"} className="img-fluid" alt="" />
									<span className="sub-titel">
										Candidates
									</span>
								</div>
								<div className="advantages-content">
									<ul>
										<li>Create and maintain a network of relevant recruiters.</li>
										<li>Stay connected with your favourite recruiters.</li>

									</ul>
									<NavLink to="/Candidates" className="btn-custom knowMore">More</NavLink>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>



			<section className="content-wrpper py-5">
				<div className="container">
					<div className="row">
						<div className="col-sm-12 col-md-6 col-lg-7 order-md-2 d-flex align-items-center ">
							<div className="content-box ps-5">
								<h2>How Opportunity Plate Works</h2>
								<p>
									Opportunity Plate is a virtual rolodex of candidates and recruiters to network and connect to
									the right opportunities.<br />
									Download the app and create a network that lasts beyond a single job opportunity.
									By creating a recruiter / candidate profile you can add and stay connected to a plethora of
									connections that can tapped and grown over time. The algorithm of Opportunity Plate is
									designed to link skills with opportunities that go beyond traditional searches. Bridging the
									communication gap between recruiters and candidates the app allows instant notification and
									chats that help both manage a job application process in a more productive way.
								</p>
							</div>
						</div>
						<div className="col-sm-12 col-md-6 col-lg-5 ps-0 d-flex justify-content-end">
							<div className="content-image">
								<img src={process.env.PUBLIC_URL + "/assets/images/abt1.png"} className="img-fluid" alt="" />
							</div>
						</div>
					</div>
					<div className="row pt-5">
						<div className="col-sm-12 col-md-6 col-lg-7 d-flex align-items-center justify-content-end">
							<div className="content-box pe-5">
								<p>
									Opportunity plate provides the right fit each time facilitating a recruiter to redeploy
									their candidate pipelines in a more effective manner.<br />
									The ability to match your skills to relevant job opportunities saves a lot of time and
									effort in the job-hunting process. Redeploying a candidate pipeline optimises the contacts
									and develops the recruiter profile with prime candidates. Opportunity Plate App helps job
									seekers to keep their favorite recruiters in one place and stay connected with them for
									present and future
									job opportunities. Opportunity Plate removes the stress, ambiguity and negative candidate
									experiences
								</p>
							</div>
						</div>
						<div className="col-sm-12 col-md-3 col-lg-5 pe-0 ">
							<div className="content-image">
								<img src={process.env.PUBLIC_URL + "/assets/images/abt2.png"} className="img-fluid" alt="" style={{objectFit: "cover" }} />
							</div>
						</div>
					</div>
				</div>
			</section>


{/*<!-- Modal --> */}
<div className="modal fade" id="appModal" tabindex="-1" aria-labelledby="planModalLabel" aria-hidden="true">
				<div className="modal-dialog modal-lg">
					<div className="modal-content">
						<div className="modal-header">
							<h1 className="modal-title fs-5" id="planModalLabel">Coming Soon!</h1>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
						</div>
						<div className="modal-body">
							<div className="row">
								<div className="col-sm-7 text-center mx-auto">
									<div className="slider-content">
										<img src={process.env.PUBLIC_URL + "/assets/images/coming-soon.png"} alt="" className="img-fluid"/>
										<p className='text-center'>We are working on it...</p>
										<h1>Coming Soon!</h1>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Home