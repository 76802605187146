import React from "react";

const Terms = () => {
  return (
    <div>
      <div className="page-bnr">
        <h1 className="fw-bold mb-0 text-center">Terms of Service</h1>
      </div>
      <section className="inner-page-wrapper py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="about-us-content">
                <h3>ACCEPTANCE OF TERMS OF USE</h3>
                <p>
                  OPPORTUNITYPLATE, LLC., dba: OPPORTUNITYPLATE (together,
                  “Company,” “we,” “us,” or “our”) owns, operated and provides
                  you (“User/s”) access to our Application (the “App”) located
                  at{" "}
                  <a target="_blank" href="https://www.opportunityplate.com">
                    www.opportunityplate.com
                  </a>{" "}
                  (the “App” or “Service/s”) subject to the following Terms of
                  Service, which may be updated from time to time, with or
                  without notice to you. By browsing the public areas of the
                  App, or by accessing and using the App, you acknowledge that
                  you have read, understood, and agree to be legally bound by
                  these Terms of Service and Privacy Policy which are hereby
                  incorporated by reference (collectively, this “Agreement”).
                </p>

                <p>
                  “User” or “You/you” shall refer to any individual or entity
                  that is bound and or obligated by this Agreement. If you
                  accept or agree to the Agreement on behalf of a company or
                  other legal entity, you represent and warrant that you have
                  the authority to bind that company or other legal entity to
                  the Agreement and, in such event, “you” and “your” will refer
                  and apply to that company or other legal entity. If you do not
                  agree to any of these terms, then please do not use the App.
                  *Capitalized terms not defined in these Terms of Service shall
                  have the meaning set forth in our Privacy Policy.
                </p>

                <p>
                  THE SECTIONS BELOW TITLED “BINDING ARBITRATION” AND “CLASS
                  ACTION WAIVER” CONTAIN A BINDING ARBITRATION AGREEMENT AND
                  CLASS ACTION WAIVER. THEY AFFECT YOUR LEGAL RIGHTS. PLEASE
                  READ THEM.
                </p>
                <p>
                  Any User desiring to utilize OPPORTUNITYPLATE is bound by the
                  Terms and Privacy policies herein.
                </p>
                <ol>
                  <li>SERVICES</li>
                </ol>
                <p>
                  OPPORTUNITYPLATE is a communication and contact App that makes
                  the process of recruiting for, applying to, and managing
                  elements of job searching seamlessly. OPPORTUNITYPLATE’s
                  “Users” fall into two categories: Candidates and Recruiters.
                  Candidates are individuals that are looking for or interested
                  in looking for employment opportunities. “Recruiters” are
                  individuals or entities that are looking for or interested in
                  looking for individual for employment opportunities. For the
                  purposes of these Terms and Conditions, both Candidates and
                  Recruiters shall be known as “User”, “Users”, “you” or “your”.
                </p>

                <p>
                  Users (both Candidate and Recruiter) will use the Service (and
                  related services) and information about users only to submit
                  or recruit individuals to become employees and consultants of
                  User or its Affiliates, or, if User is acting as a Staffing
                  Agency, only to recruit individuals to become employees and
                  consultants of its clients. "Staffing Agency" means a User
                  that uses the Services to recruit on behalf of a third-party
                  client using User's own name and/or logo, including without
                  limitation, staffing agencies, executive search firms, and
                  direct hire firms.
                </p>

                <p>
                  Users interact with OPPORTUNITYPLATE depending and each other
                  on the account they register with:
                </p>

                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <ul>
                          <li>Candidates</li>
                          <li>Create/share resumes</li>
                          <li>Apply for jobs</li>
                          <li>Schedule/Reschedule interviews</li>
                          <li>
                            Can view any job opportunities w/ recruiter that is
                            connected
                          </li>
                          <li>
                            Interact through the App with any connected User
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Recruiters</li>
                          <li>Create/share job opportunities</li>
                          <li>Send interviews requests</li>
                          <li>Can view any candidate that is connected</li>
                          <li>
                            Interact through the App with any connected User
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>

                <ol start="2">
                  <li>ELIGIBILITY</li>
                </ol>
                <p>
                  The App is available for individuals aged 18 years or older.
                  By creating an account on our App, you agree to provide true,
                  accurate, current, and complete information. You agree not to
                  create an account using a false identity or providing false
                  information or if you have previously been removed or banned
                  from our App. You are responsible for maintaining the
                  confidentiality of your account information, including your
                  username and password. You are responsible for all activities
                  that occur on or in connection with your account and you agree
                  to notify us immediately of any unauthorized access or use of
                  your account. You acknowledge and agree that we are not
                  responsible or liable for any damages, losses, costs,
                  expenses, or liabilities related to any unauthorized access to
                  or use of your account. *Company reserves the right to accept
                  or not accept any registration request for any reason or no
                  reason, at Company’s sole discretion. Company reserves the
                  right to terminate any user for any or no reason at any time,
                  at Company’s sole discretion (see Section 11, below).
                </p>

                <ol start="3">
                  <li>USAGE GUIDELINES</li>
                </ol>
                <p>
                  Company’s Services, functions best when its people follow a
                  few simple rules. By accessing and/or using the App or other
                  services, you hereby agree to comply with these rules:
                </p>
                <ul>
                  <li>
                    You will comply with all applicable laws in your use of
                    Services, and you will not use the Services for any unlawful
                    purpose;
                  </li>
                  <li>
                    You will not access or use the Services to collect any
                    market research for a competing business;
                  </li>
                  <li>
                    You will not upload, post, e-mail, transmit, or otherwise
                    make available any content that:
                    <ul>
                      <li>
                        infringes any copyright, trademark, or other proprietary
                        rights of any person or entity; or
                      </li>
                      <li>
                        is threatening, tortious, defamatory, libelous,
                        indecent, obscene, pornographic, invasive of another’s
                        privacy, or promotes violence; or
                      </li>
                      <li>
                        discloses any personal information about another person,
                        including that person’s name,
                      </li>
                      <li>
                        e-mail address, postal address, phone number, credit
                        card information, or any similar information;
                      </li>
                    </ul>
                  </li>
                  <li>
                    You will not impersonate any person or entity or falsely
                    state or otherwise misrepresent your affiliation with a
                    person or entity;
                  </li>
                  <li>
                    You will not “stalk,” threaten, or otherwise harass another
                    person;
                  </li>
                  <li>
                    You will not use automated means, including spiders, robots,
                    crawlers, data mining tools, or the like to download or
                    scrape data from the App, directly or indirectly, except for
                    Internet search engines (e.g., Google) and non-commercial
                    public archives (e.g., archive.org) that comply with our
                    robots.txt file;
                  </li>
                  <li>
                    You will not take any action that imposes or may impose (in
                    our sole discretion) an unreasonable or disproportionately
                    large load on our technical infrastructure;
                  </li>
                  <li>
                    You will not cover, obscure, block, or in any way interfere
                    with any safety features (e.g., report abuse button) on the
                    Services; and
                  </li>
                  <li>
                    You will not interfere with or attempt to interrupt the
                    proper operation of the Services through the use of any
                    virus, device, information collection or transmission
                    mechanism, software or routine, or access or attempt to gain
                    access to any data, files, or passwords related to the
                    Services through hacking, password or data mining, or any
                    other means.
                  </li>
                  <li>
                    Objectionable User Content. If you find something that
                    violates our Usage Guidelines, please let us know, and we’ll
                    review it. We reserve the right, in our sole and absolute
                    discretion, to delete any/all User Content, suspend or deny
                    you (or any device) access to the Services, or any portion
                    of thereof, without notice.
                  </li>
                  <li>
                    You will let us know about inappropriate content of which
                    you become aware. If you find something that violates our
                    Usage Guidelines, please let us know, and we’ll review it.
                    We reserve the right, in our sole and absolute discretion,
                    to deny you (or any device) access to the Services, or any
                    portion of thereof, without notice.
                  </li>
                </ul>

                <ul start="3">
                  <li>3(a). USER CONTENT.</li>
                  <ul>
                    <li>
                      User Content is all information provided by the User while
                      interacting with the App. As a Candidate or Recruiter, you
                      may upload, modify, discuss, or otherwise communicate for
                      the purposes of job searching and candidate searching.
                    </li>
                    <li>
                      You agree that all User Content will be professional and
                      appropriate. OPPORTUNITYPLATE has a ZERO-TOLERANCE for any
                      Objectionable User Content. If notified of potential
                      instances of Objectionable User Content, OPPORTUNITYPLATE
                      retains the right to remove any Objectionable User Content
                      and suspend or block users or devices that post such
                      content.
                    </li>
                  </ul>
                  <li>3(b). OBJECTIONABLE USER CONTENT.</li>
                  <ul>
                    <li>
                      Objectionable User Content is any User Content that
                      contains offensive content, such as: offensive,
                      defamatory, discriminatory, insensitive, upsetting,
                      intended to disgust, or in exceptionally poor taste.
                      Examples of such content include but are not limited to:
                    </li>

                    <ul>
                      <ol type="i">
                        <li>
                          Defamatory, discriminatory, or mean-spirited content,
                          including references or commentary about religion,
                          race, sexual orientation, gender, national/ethnic
                          origin, or other protected groups.
                        </li>
                        <li>Bullying and harassment of any kind.</li>
                        <li>
                          Encouraging illegal activities or reckless
                          applications of legal activities.
                        </li>
                        <li>Content that portrays or encourages violence.</li>
                        <li>
                          Overtly sexual or pornographic language or materials,
                          defined as “explicit descriptions or displays of
                          sexual organs or activities.”
                        </li>
                        <li>
                          Inappropriate content in light of the purpose of
                          OPPORTUNITYPLATE.
                        </li>
                      </ol>
                    </ul>
                  </ul>

                  <li>3(c).JOB POSTING,CHAT REPORTING AND ACTION PROTOCOAL</li>
                  <ul>
                    <li>PROCEDURE FOR REPORTING JOB POSTINGS:</li>
                    <ul>
                      <ul>
                        <li>1. Selecting a Job Posting for Reporting:</li>
                        <ul>
                          <li>
                            Users ( Candidate) will choose a specific job
                            posting to report.
                          </li>
                        </ul>
                      </ul>
                      <ul>
                        <li> 2. Completion of Reporting Form:</li>
                        <ul>
                          <li>
                            Users ( Candidate) will choose a specific job
                            posting to report.
                          </li>
                        </ul>
                        <ul>
                          <ol type="i">
                            <li>Report text</li>
                            <li>
                              Upload Screenshots of the job post (if applicable)
                            </li>
                            <li>
                              The job posting will be concealed from the
                              reporting user(candidate).
                            </li>
                          </ol>
                        </ul>
                      </ul>
                      <ul>
                        <li>3. Email Notifications Upon Submission:</li>

                        <ul>
                          <li>
                            Upon submission, the following emails will be
                            triggered:
                          </li>
                        </ul>
                        <ul>
                          <ol type="i">
                            <li>
                              Confirmation email sent to the user/candidate who
                              reported.
                            </li>
                            <li>New report email sent to the admin.</li>
                          </ol>
                        </ul>
                      </ul>
                      <ul>
                        <ol start="4">
                          <li>Admin Response Options:</li>
                        </ol>
                        <ul>
                          <li>
                            OPPORTUNITYPLATE Admins will have three options to
                            choose from for each report:
                          </li>

                          <li>1. OPTION 1: Delete Job Posting:</li>

                          <ul>
                            <li>The job posting will be removed.</li>
                            <li>
                              The recruiter will receive an explanatory email
                              from the admin regarding the deletion.
                            </li>
                          </ul>

                          <li>
                            2. OPTION 2: Suspend Recruiter Account (Reserved for
                            Extreme Cases):
                          </li>

                          <ul>
                            <li>
                              This action is reserved for severe infractions,
                              particularly for repeat offenders
                            </li>
                          </ul>
                          <ul>
                            <li>
                              The recruiter's account will be temporarily
                              suspended, accompanied by a comprehensive email
                              detailing the reasons for the suspension
                            </li>
                            <li>
                              Recruiters will have the chance to provide an
                              explanation in response to prevent suspension.
                            </li>
                            <li>
                              If the explanation provided by the recruiter is
                              deemed satisfactory, the account suspension will
                              be revoked. However, if concerns persist, the
                              account may face permanent suspension.
                            </li>
                          </ul>
                          <li>3. OPTION 3 - Take No Action:</li>
                          <ul>
                            <li>
                              In cases where the admin determines that the job
                              posting content or behavior is compliant with
                              OPPORTUNITYPLATE Platform policy, no action will
                              be taken
                            </li>
                            <li>
                              Users involved will not receive any emails or
                              notifications regarding the reported issue.
                            </li>
                          </ul>
                        </ul>
                      </ul>

                      <ul>
                        <li>CHAT BLOCKING / UNBLOCKING PROCEDURES:</li>
                        <ul>
                          <ol start="1">
                            <li>REPORTING AND BLOCKING OFFENSIVE CHATS:</li>
                            <ul>
                              <li>
                                Users have the option to "Report and Block" a
                                chat deemed offensive.
                              </li>
                              <li>
                                Upon being Reported and Blocked , The
                                OPPORTUNITYPLATE admin will review the last 10
                                messages of the reported user.
                              </li>
                              <li>
                                Once a user is Reported and Blocked, both
                                parties will be unable to view each other’s
                                profiles, activities, and chat history.
                              </li>
                            </ul>
                          </ol>
                          <ol start="2">
                            <li>UNBLOCKING:</li>
                          </ol>
                          <ul>
                            <li>
                              The reporting user has the ability to unblock the
                              reported user at any time. Upon unblocking, both
                              users will regain access to view each other's
                              profiles, activities, and chat history.
                            </li>
                          </ul>
                        </ul>
                      </ul>
                    </ul>
                  </ul>
                </ul>

                <ol start="4">
                  <li>CHARGES, PAYMENT AND RETURNS.</li>
                  <li>
                    <u>Charges</u>: You agree to pay all amounts due arising out
                    of your use of the Services.
                  </li>
                  <li>
                    <u>Currency</u>: All transactions are in U.S. dollars
                    (“USD”) unless otherwise specified at point of purchase.
                  </li>
                  <li>
                    <u>Third Party Payment Processing</u>: OPPORTUNITYPLATE uses
                    In-App Puchase APIs from Google and Apple, a third-party
                    electronic payment processing provider for payment services
                    (e.g. card acceptance, merchant settlement, and related
                    services) ("Payment Services") for payments related to the
                    Services. By using OPPORTUNITYPLATE Services, you agree to
                    be bound by In-App Puchase from Google and Apple terms and
                    conditions as well as their privacy policy . Further, you
                    hereby consent and authorize us to delegate the
                    authorizations and share the information you provide to us
                    with our third-party electronic payment processing
                    provider(s) to the extent required to provide the Payment
                    Services to you.
                  </li>
                  <li>
                    <u>Payment Questions</u>: If you have any payment related
                    issues or questions, OPPORTUNITYPLATE can be contacted
                    directly for payments support at{" "}
                    <a href="https://www.opportunityplate.com/Contact">
                      https://www.opportunityplate.com/Contact
                    </a>
                    . We have the sole discretion to determine how billing
                    disputes between us will be resolved.
                  </li>
                  <li>
                    <u>All Payments Are Final</u>.
                  </li>
                </ol>
                <ol start="10">
                  <li>USE OF PERSONAL INFORMATION</li>
                </ol>
                <p>
                  Your use of the Services may involve the transmission to us of
                  certain Personal Information. Our policies with respect to the
                  collection and use of such Personal Information are governed
                  according to our <u>Privacy Policy</u> (located at{" "}
                  <a href="https://www.opportunityplate.com/Privacy">
                    https://www.opportunityplate.com/Privacy
                  </a>
                  ), which is hereby incorporated by reference in its entirety.
                </p>

                <ol start="11">
                  <li>INTELLECTUAL PROPERTY</li>
                </ol>
                <p>
                  The Services contain materials, such as software, text,
                  graphics, images, sound recordings, audiovisual works, and
                  other materials provided by or on behalf of Company
                  (collectively referred to as the “Content”). The Content may
                  be owned by us or by third parties. The Content is protected
                  under both United States and foreign laws. Unauthorized use of
                  the Content may violate copyright, trademark, and other laws.
                  You have no rights in or to the Content, and you will not use
                  the Content except as permitted under this Agreement. You must
                  retain all copyright and other proprietary notices contained
                  in the original Content on any copy you make of the Content.
                  You may not sell, transfer, assign, license, sublicense, or
                  modify the Content or reproduce, display, publicly perform,
                  make a derivative version of, distribute, or otherwise use the
                  Content in any way for any public or commercial purpose. The
                  use or posting of the Content on any other App or in a
                  networked computer environment for any purpose is expressly
                  prohibited. If you violate any part of this Agreement, your
                  permission to access and/or use the Content and the Services
                  automatically terminates and you must immediately destroy any
                  copies you have made of the Content.
                </p>

                <p>
                  The trademarks, service marks, and logos of Company (“Company
                  Trademarks”) used and displayed on the Services are registered
                  and unregistered trademarks or service marks of
                  OPPORTUNITYPLATE, LLC. Other company, product, and service
                  names located on the Services may be trademarks or service
                  marks owned by others (the “Third-Party Trademarks”, and,
                  collectively with Company Trademarks, the “Trademarks”).
                  Nothing about the Services should be construed as granting, by
                  implication, estoppel, or otherwise, any license or right to
                  use the Trademarks, without our prior written permission
                  specific for each such use. Use of the Trademarks as part of a
                  link to or from any site is prohibited unless establishment of
                  such a link is approved in advance by us in writing. All
                  goodwill generated from the use of Company Trademarks inures
                  to our benefit.
                </p>

                <p>
                  Elements of the Services are protected by trade dress,
                  trademark, unfair competition, and other state and federal
                  laws and may not be copied or imitated in whole or in part, by
                  any means, including but not limited to the use of framing or
                  mirrors. None of the Content may be retransmitted without our
                  express, written consent for each and every instance.
                </p>

                <ol start="12">
                  <li>EXTERNAL LINKS, THIRD PARTY CONTENT AND INTERACTIONS.</li>
                </ol>
                <p>
                  The App may contain features and functionalities that link to
                  or provide you with access to third party content, that is
                  completely independent of Company, including Apps, platforms,
                  directories, servers, networks, systems, information,
                  databases, Apps, software, programs, products or services, and
                  the Internet in general. These links are provided solely as a
                  convenience to you and not as an endorsement by us of the
                  content on such External Sites. Your interactions with and
                  third parties, including end-users on or through our App or
                  Services are solely between you and the third party. We are
                  not responsible for the content of any linked External Sites
                  and do not make any representations regarding the content or
                  accuracy of materials on such External Sites. You should take
                  precautions when downloading files from any sourced to protect
                  your computer from viruses and other destructive programs. If
                  you decide to access linked External Sites, you do so at your
                  own risk.
                </p>

                <p>
                  You should make whatever investigation you feel necessary or
                  appropriate before proceeding with any contact or interaction,
                  in connection with our App. However, you agree not to contact
                  or interact with any third-party except as expressly permitted
                  through our App. You also agree that Company may, in its sole
                  discretion, intercede in any dispute and you will reasonably
                  cooperate with Company if it does so. You acknowledge and
                  agree that we are not responsible or liable for any damages,
                  losses, costs, expenses, or liabilities of any kind or nature
                  incurred as the result of any such interaction. You hereby
                  release each Company Party (defined below) from claims,
                  demands, and damages (actual and consequential) of every kind
                  or nature, known and unknown, suspected, and unsuspected,
                  disclosed and undisclosed, arising out of or in any way
                  related to such disputes or our Site.
                </p>

                <p>
                  *IF YOU ARE A CALIFORNIA RESIDENT, YOU HEREBY WAIVE CALIFORNIA
                  CIVIL CODE SECTION 1542 (AND ANY OTHER SIMILAR APPLICABLE
                  STATE STATUTE), WHICH PROVIDES: “A GENERAL RELEASE DOES NOT
                  EXTEND TO CLAIMS THAT THE CREDITOR OR RELEASING PARTY DOES NOT
                  KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF
                  EXECUTING THE RELEASE AND THAT, IF KNOWN BY HIM OR HER, WOULD
                  HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR
                  OR RELEASED PARTY".
                </p>

                <ol start="13">
                  <li>COMMUNICATIONS TO COMPANY</li>
                </ol>
                <p>
                  Although we encourage you to e-mail us/contact us through your
                  account, we do not want you to, and you should not e-mail us
                  any content that contains confidential information. With
                  respect to all e-mails you send to us, including but not
                  limited to, feedback, questions, comments, suggestions, and
                  the like, we shall be free to use any ideas, concepts,
                  know-how, or techniques contained in your communications for
                  any purpose whatsoever, including but not limited to, the
                  development, production and marketing of products and services
                  that incorporate such information.
                </p>

                <ol start="14">
                  <li>NO WARRANTIES/LIMITATION OF LIABILITY</li>
                </ol>
                <p>
                  THE APP, PLATFORM AND SERVICES ARE PROVIDED ON AN “AS IS” AND
                  “AS AVAILABLE” BASIS, AND COMPANY DOES NOT MAKE ANY WARRANTIES
                  WITH RESPECT TO THE SAME OR OTHERWISE IN CONNECTION WITH THIS
                  AGREEMENT. COMPANY HEREBY DISCLAIMS ANY AND ALL EXPRESS,
                  IMPLIED, OR STATUTORY WARRANTIES, INCLUDING, WITHOUT
                  LIMITATION, ANY WARRANTIES OF NON-INFRINGEMENT,
                  COMPANYABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                  AVAILABILITY, ERROR-FREE OR UNINTERRUPTED OPERATION, AND ANY
                  WARRANTIES ARISING FROM A COURSE OF DEALING, COURSE OF
                  PERFORMANCE, OR USAGE OF TRADE.
                </p>

                <p>
                  IN CONNECTION WITH ANY WARRANTY, CONTRACT, OR COMMON LAW TORT
                  CLAIMS: (I) WE SHALL NOT BE LIABLE FOR ANY INCIDENTAL AND
                  CONSEQUENTIAL DAMAGES, LOST PROFITS, OR DAMAGES RESULTING FROM
                  LOST DATA OR BUSINESS INTERRUPTION RESULTING FROM THE USE OR
                  INABILITY TO USE THE APP, OR THE CONTENT, AND (II) ANY DIRECT
                  DAMAGES THAT YOU MAY SUFFER AS A RESULT OF YOUR USE OF THE
                  APP, OR THE CONTENT SHALL BE LIMITED TO PURCHASE AMOUNT OF THE
                  TRANSACTION IN QUESTION. SOME JURISDICTIONS DO NOT ALLOW THE
                  EXCLUSION OF CERTAIN WARRANTIES. THEREFORE, SOME OF THE ABOVE
                  LIMITATIONS ON WARRANTIES IN THIS SECTION MAY NOT APPLY TO
                  YOU.
                </p>

                <p>
                  THE APP MAY CONTAIN TECHNICAL INACCURACIES OR TYPOGRAPHICAL
                  ERRORS OR OMISSIONS. WE ARE NOT RESPONSIBLE FOR ANY SUCH
                  TYPOGRAPHICAL, TECHNICAL, OR PRICING ERRORS LISTED ON THE App.
                  WE RESERVE THE RIGHT TO MAKE CHANGES, CORRECTIONS, AND/OR
                  IMPROVEMENTS TO THE App AT ANY TIME WITHOUT NOTICE.
                </p>

                <ol start="15">
                  <li>INDEMNIFICATION</li>
                </ol>
                <p>
                  You agree to defend, indemnify, and hold the Company, its
                  affiliates, and their respective shareholders, members,
                  officers, directors, employees, agents, and representatives
                  (collectively, the “Company Parties”) harmless from and
                  against any and all claims, actions, or demands and relating
                  costs, damages and liability (including, without limitation,
                  reasonable attorneys’ fees) arising or resulting from: (i)
                  your breach of this Agreement; (ii) your use of the App,
                  Platform, Services and/or the Content; or (iii) your violation
                  of any third-party right, including without limitation any
                  intellectual property, or privacy right.
                </p>

                <ol start="16">
                  <li>COMPLIANCE WITH APPLICABLE LAWS</li>
                </ol>
                <p>
                  The Services are based in the United States. We make no claims
                  concerning whether the Content may be downloaded, viewed, or
                  be appropriate for use outside of the United States. If you
                  access the Services, or the Content from outside of the United
                  States, you do so at your own risk. Whether inside or outside
                  of the United States, you are solely responsible for ensuring
                  compliance with the laws of your specific jurisdiction.
                </p>

                <ol start="17">
                  <li>TERMINATION OF THE AGREEMENT; CANCELLATION OF ACCOUNT</li>
                </ol>
                <p>
                  Either Party reserves the right to cancel an account on the
                  App at will. You may terminate and delete your account using
                  the App or by emailing us at{" "}
                  <a href="mailto:delete@opportunityplate.com">
                    delete@opportunityplate.com
                  </a>
                  . We reserve the right, in our sole discretion, to restrict,
                  suspend, or terminate this Agreement and your and any device’s
                  access to all or any part of the App, at any time and for any
                  reason without prior notice or liability. We reserve the right
                  to change, suspend, or discontinue all or any part of the App
                  at any time without prior notice or liability.
                </p>

                <ol start="18">
                  <li>DIGITAL MILLENNIUM COPYRIGHT ACT</li>
                </ol>
                <p>
                  Company respects the intellectual property rights of others
                  and attempts to comply with all relevant laws. We will review
                  all claims of copyright infringement received and remove any
                  Content or User submissions deemed to have been posted or
                  distributed in violation of any such laws. Our designated
                  agent under the Digital Millennium Copyright Act (the “Act”)
                  for the receipt of any Notification of Claimed Infringement
                  which may be given under that Act is as follows:
                  OPPORTUNITYPLATE, LLC. Attention: DMCA 11942 Alderbrook
                  Street, Moorpark CA 93021.
                </p>

                <p>
                  If you believe that your work has been copied on or in the
                  Services in a way that constitutes copyright infringement,
                  please provide our agent with notice in accordance with the
                  requirements of the Act, including (i) a description of the
                  copyrighted work that has been infringed and the specific
                  location on the Services where such work is located; (ii) a
                  description of the location of the original or an authorized
                  copy of the copyrighted work; (iii) your address, telephone
                  number and e-mail address; (iv) a statement by you that you
                  have a good faith belief that the disputed use is not
                  authorized by the copyright owner, its agent or the law; (v) a
                  statement by you, made under penalty of perjury, that the
                  information in your notice is accurate and that you are the
                  copyright owner or authorized to act on the copyright owner’s
                  behalf; and (vi) an electronic or physical signature of the
                  owner of the copyright or the person authorized to act on
                  behalf of the owner of the copyright interest.
                </p>

                <ol start="19">
                  <li>BINDING ARBITRATION</li>
                </ol>
                <p>
                  In the event of a dispute arising under or relating to this
                  Agreement or the Services (each, a “Dispute”), either party
                  may elect to finally and exclusively resolve the dispute by
                  binding arbitration governed by the Federal Arbitration Act
                  (“FAA”). Any election to arbitrate, at any time, shall be
                  final and binding on the other party. IF EITHER PARTY CHOOSES
                  ARBITRATION, NEITHER PARTY SHALL HAVE THE RIGHT TO LITIGATE
                  SUCH CLAIM IN COURT OR TO HAVE A JURY TRIAL, EXCEPT EITHER
                  PARTY MAY BRING ITS CLAIM IN ITS LOCAL SMALL CLAIMS COURT, IF
                  PERMITTED BY THAT SMALL CLAIMS COURT RULES AND IF WITHIN SUCH
                  COURT’S JURISDICTION. ARBITRATION IS DIFFERENT FROM COURT, AND
                  DISCOVERY AND APPEAL RIGHTS MAY ALSO BE LIMITED IN
                  ARBITRATION. All disputes will be resolved before a neutral
                  arbitrator selected jointly by the parties, whose decision
                  will be final, except for a limited right of appeal under the
                  FAA. The arbitration shall be commenced and conducted by JAMS
                  pursuant to its then current Comprehensive Arbitration Rules
                  and Procedures and in accordance with the Expedited Procedures
                  in those rules, or, where appropriate, pursuant to JAMS’
                  Streamlined Arbitration Rules and Procedures. All applicable
                  JAMS’ rules and procedures are available at the JAMS App{" "}
                  <a href="https://www.jamsadr.com" target="_blank">
                    www.jamsadr.com
                  </a>
                  . The claiming party will be responsible for paying any JAMS
                  filing, administrative, and arbitrator fees in accordance with
                  JAMS rules. Judgment on the arbitrator’s award may be entered
                  in any court having jurisdiction. This clause shall not
                  preclude parties from seeking provisional remedies in aid of
                  arbitration from a court of appropriate jurisdiction. The
                  arbitration may be conducted in person, through the submission
                  of documents, by phone, or online. If conducted in person, the
                  arbitration shall take place in the Los Angeles County, CA,
                  United States. The parties may litigate in court to compel
                  arbitration, to stay a proceeding pending arbitration, or to
                  confirm, modify, vacate, or enter judgment on the award
                  entered by the arbitrator. The parties shall cooperate in good
                  faith in the voluntary and informal exchange of all
                  non-privileged documents and other information (including
                  electronically stored information) relevant to the Dispute
                  immediately after commencement of the arbitration. Nothing in
                  this Agreement will prevent Company from seeking injunctive
                  relief in any court of competent jurisdiction as necessary to
                  protect Company’s proprietary interests.
                </p>

                <ol start="20">
                  <li>CLASS ACTION WAIVER</li>
                </ol>
                <p>
                  You agree that any arbitration or proceeding shall be limited
                  to the Dispute between us and you individually. To the full
                  extent permitted by law, (i) no arbitration or proceeding
                  shall be joined with any other; (ii) there is no right or
                  authority for any Dispute to be arbitrated or resolved on a
                  class action-basis or to utilize class action procedures; and
                  (iii) there is no right or authority for any Dispute to be
                  brought in a purported representative capacity on behalf of
                  the general public or any other persons.
                </p>

                <p>
                  YOU AGREE THAT YOU MAY BRING CLAIMS AGAINST US ONLY IN YOUR
                  INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
                  ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING.
                </p>

                <ol start="21">
                  <li>MISCELLANEOUS</li>
                </ol>
                <p>
                  If this Agreement is terminated in accordance with the
                  Termination provision above, such termination shall not affect
                  the validity of the following provisions of this Agreement,
                  which shall remain in full force and effect: “Intellectual
                  Property,” “Communications to Company,” “No
                  Warranties/Limitation of Liability,” “Indemnification,”
                  “Termination of the Agreement,” “Compliance with Applicable
                  Laws,” “Binding Arbitration,” “Class Action Waiver,”
                  “Equitable Relief,” and “Miscellaneous.” You may not assign
                  this Agreement. No waiver shall be effective unless in
                  writing. Neither the course of conduct between parties nor
                  trade practice shall act to modify any provision of this
                  Agreement. This Agreement shall be governed by and construed
                  in accordance with the laws of the State of California,
                  without giving effect to its principles of conflicts of laws.
                  Except for proceedings commenced by Company to protect its
                  intellectual property or confidential information which may be
                  brought in any court of competent jurisdiction, the parties
                  mutually agree that any and all disputes arising hereunder
                  shall be resolved exclusively by state or federal courts
                  located in the Los Angeles County, CA, United States. Our
                  failure to act on or enforce any provision of the Agreement
                  shall not be construed as a waiver of that provision or any
                  other provision in this Agreement. No waiver shall be
                  effective against us unless made in writing, and no such
                  waiver shall be construed as a waiver in any other or
                  subsequent instance. This Agreement contains the entire
                  agreement of the parties concerning its subject matter, and
                  supersedes all existing agreements and all other oral, written
                  or other communication between the parties concerning the
                  subject matter. The section headings are provided merely for
                  convenience and shall not be given any legal import. This
                  Agreement will inure to the benefit of our successors,
                  assigns, licensees, and sublicensees.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Terms;
