import React from 'react';

const JobPostingGuidelines = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Prohibited Words/Phrases and Reasons for Job Postings</h1>
      <p style={styles.paragraph}>
        When creating job postings, it is crucial to avoid certain words and phrases to comply with legal
        requirements and prevent discrimination. Below are the words/phrases to avoid and the reasons
        for their prohibition.
      </p>
      
      <h2 style={styles.subHeader}>Age Discrimination</h2>
      <p style={styles.boldText}>Prohibited Words/Phrases:</p>
      <ul style={styles.list}>
        <li style={styles.listItem}>• recent grad</li>
        <li style={styles.listItem}>• young grad</li>
        <li style={styles.listItem}>• recent graduate</li>
        <li style={styles.listItem}>• young graduate</li>
        <li style={styles.listItem}>• recent college grad</li>
        <li style={styles.listItem}>• young</li>
        <li style={styles.listItem}>• new graduate</li>
        <li style={styles.listItem}>• new grad</li>
        <li style={styles.listItem}>• new college graduate</li>
        <li style={styles.listItem}>• college student</li>
      </ul>
      <p style={styles.paragraph}><span style={styles.boldText}>Reason:</span> These terms can imply a preference for younger candidates, which is a form of
        age discrimination.</p>
      
      <h2 style={styles.subHeader}>State Pre-Employment Laws</h2>
      <p style={styles.boldText}>Prohibited Words/Phrases:</p>
      <ul style={styles.list}>
        <li style={styles.listItem}>• criminal</li>
        <li style={styles.listItem}>• background check</li>
        <li style={styles.listItem}>• drug screen</li>
        <li style={styles.listItem}>• drug screening</li>
        <li style={styles.listItem}>• drug test</li>
        <li style={styles.listItem}>• credit check</li>
        <li style={styles.listItem}>• reference check</li>
        <li style={styles.listItem}>• pre-employment verification</li>
        <li style={styles.listItem}>• background investigation</li>
      </ul>
      <p style={styles.paragraph}><span style={styles.boldText}>Reason:</span> These terms are restricted by various state laws regarding pre-employment
        practices and can lead to non-compliance.</p>
      
      <h2 style={styles.subHeader}>National Origin Discrimination</h2>
      <p style={styles.boldText}>Prohibited Words/Phrases:</p>
      <ul style={styles.list}>
        <li style={styles.listItem}>• native speakers only</li>
        <li style={styles.listItem}>• native speaker</li>
      </ul>
      <p style={styles.paragraph}><span style={styles.boldText}>Reason:</span> These terms can imply a preference based on national origin, which is
        discriminatory.</p>
      
      <h2 style={styles.subHeader}>Guidelines for Compliance</h2>
      <p style={styles.paragraph}>
        To ensure your job postings are compliant and inclusive, avoid using the prohibited
        words/phrases listed above. Instead, focus on the skills, qualifications, and experience required
        for the role without referencing age, legal background checks, or national origin. This approach
        will help attract a diverse pool of candidates and maintain legal compliance.
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '0 auto',
    color: '#333',
  },
  header: {
    textAlign: "center",
    color: '#2c3e50',
    fontSize: '2.5em',
    marginBottom: '20px',
  },
  subHeader: {
    color: '#34495e',
    fontSize: '1.5em',
    marginTop: '20px',
    marginBottom: '10px',
  },
  paragraph: {
    marginBottom: '20px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  list: {
    paddingLeft: '20px',
    marginBottom: '20px',
    listStyleType: 'none',
  },
  listItem: {
    marginBottom: '5px',
  },
};

export default JobPostingGuidelines;
