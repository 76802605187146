import React from 'react'
import { NavLink } from 'react-router-dom'

const Footer = () => {
	const date = new Date()
	return (
		<div>
			<footer className="pt-5">
				<div className="container">
					<div className="row justify-content-between">
						<div className="col-sm-12 col-md-4 col-lg-4 d-md-flex justify-content-md-start">
							<div className="footer-content pe-5">
								<img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} className="img-fluid" alt="logo" />
								<p>
									Your Network! Your Opportunities!
								</p>
							</div>
						</div>
						<div className="col-sm-12 col-md-5 col-lg-5 d-md-flex justify-content-md-center">
							<div className="footer-link ps-5 ps-md-0">
								<h4>Links</h4>
								<ul>
									<li>
										<NavLink to="/About">
											About us
										</NavLink>
									</li>
									<li>
										<NavLink to="/Contact">
											Contact us
										</NavLink>
									</li>
								</ul>
							</div>
						</div>
						<div className="col-sm-12 col-md-3 col-lg-3 d-md-flex justify-content-md-end">
							<div className="social-media me-md-4">
								<h4>Follow Us</h4>
								<ul>
									<li>
										<NavLink to="https://www.facebook.com/profile.php?id=61551910187686&mibextid=LQQJ4d" target="_blank">
											<i className="fab fa-facebook-f"></i>
										</NavLink>
									</li>
									<li>
										<NavLink to="https://www.instagram.com/opportunity.plate" target="_blank">
											<i className="fab fa-instagram"></i>
										</NavLink>
									</li>
									<li>
										<NavLink to="https://www.linkedin.com/company/opportunityplate/" target="_blank">
											<i className="fab fa-linkedin-in"></i>
										</NavLink>
									</li>
									{/* <li>
										<NavLink to="/">
											<span>
												<svg xmlns="http://www.w3.org/2000/svg" height="13" width="13" fill='#fff' viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>
											</span>
										</NavLink>
									</li> */}
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="footer-bottom mt-4">
					<div className="container">
						<div className="row">
							<div className="col-sm-12 col-md-8 col-lg-8">
								<div className="copyright">
									<p className='footer-copyright'>© {date.getFullYear()} Oppotunityplate LLC. All rights reserved</p>
								</div>
							</div>
							<div className="col-sm-12 col-md-4 col-lg-4">
								<div className="other-link">
									<ul>
										<li>
											<NavLink to="/Privacy">
												Privacy Policy
											</NavLink>
										</li>
										<li>
											<NavLink to="/Terms">
												Terms of Service
											</NavLink>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</div>
	)
}

export default Footer