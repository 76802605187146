import React from 'react'

const Candidate = () => {
  return (
    <div>
        <div className="page-bnr">
		<h1 className="fw-bold mb-0 text-center">Candidate</h1>
		</div>
      <section className="about-us-wrapper py-5">
		<div className="container">
			<div className="row">
				<div className="col-sm-12 col-md-12 col-lg-6">
                    <div className="about-us-content py-4">
                        <p className="mb-3"><strong>1. Expand Your Network:</strong> Strengthen your connections by inviting recruiters from your industry. A
                            strong network can lead to exciting collaborations and job prospects.</p>
                        <p className="mb-3"><strong>2. Stay Engaged:</strong> Keep an eye on your connections' activities and job posts. Don't hesitate to
                            reach out if you find a position that aligns with your aspirations.</p>
                        <p className="mb-3"><strong>3. Keep it Fresh:</strong> Regularly update your availability status and skills. This ensures that
                            recruiters and employers can find you when they have the perfect opportunity.</p>
                        <p className="mb-3"><strong>4. Explore Opportunities:</strong> Browse job listings that match your skills and preferences. Apply
                            confidently for roles that resonate with your career goals.</p>
                        <p className="mb-3"><strong>5. Direct Communication:</strong> Chat directly with recruiters for updates, sending updated resume, new
                            opportunities, or any job-related queries. Your direct line to success!</p>
                        <p className="mb-3"><strong>6. Track Your Progress:</strong> Say goodbye to application anxiety. Monitor your applications from
                            submission to offer, ensuring you're always in the loop through application tracker. </p>
                    </div>
				</div>
				<div className="col-sm-12 col-md-12 col-lg-6">
					<div className="about-us-image h-100">
						<img src={process.env.PUBLIC_URL + "/assets/images/image-02.jpg"} className="img-fluid rounded-4 shadow h-100 object-fit-cover" alt="" style={{objectPosition: "-90px"}}/>
					</div>
				</div> 
			</div>
		</div>
	</section>
    </div>
  )
}

export default Candidate