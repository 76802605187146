import React from "react";
import { NavLink } from "react-router-dom";

const Privacy = () => {
  return (
    <div>
      <div className="page-bnr">
        <h1 className="fw-bold mb-0 text-center">Privacy Policy</h1>
      </div>
      <section className="inner-page-wrapper py-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="about-us-content">
                <p>
                  This application is powered by OPPORTUNITYPLATE, LLC., dba:
                  OPPORTUNITYPLATE (together, “OPPORTUNITYPLATE”), and this
                  Privacy Policy describes the privacy practices of
                  OPPORTUNITYPLATE. References in this Privacy Policy to “we,”
                  “us,” or “our” mean OPPORTUNITYPLATE.This Privacy Policy does
                  not apply to any mobile applications, websites, or other
                  online services that do not link to this policy.
                </p>
                <p>
                  In order to provide Services through OPPORTUNITYPLATE, and
                  continue to make them better, OPPORTUNITYPLATE (“we”,
                  “OPPORTUNITYPLATE”) collects information from you. If you’re a
                  resident of California or visiting us from the Non-European
                  Economic Area ("EEA"), you should read this policy and the
                  applicable sections below. This Privacy Policy explains how
                  OPPORTUNITYPLATE collects, stores, uses, transfers, and
                  discloses your information.
                </p>
                <p>
                  From time to time, OPPORTUNITYPLATE may revise this Privacy
                  Policy. We will give you notice by posting the revised Privacy
                  Policy on our Website and updating the “Last Updated and
                  Effective” date at the top of this Privacy Policy; we may also
                  notify you in other ways, such as through the contact
                  information you have provided. Any changes to this Privacy
                  Policy will be effective immediately on posting the updated
                  Privacy Policy unless otherwise stated or as required by
                  applicable law (for example, notice and opt-in or opt-out
                  consent may be required). By continuing to use our Services,
                  you agree to the revised Privacy Policy to the fullest extent
                  permitted by applicable law.
                </p>
                <p>
                  Please also review the{" "}
                  <u>
                    <NavLink to="https://www.opportunityplate.com/Terms">
                      Terms of Service
                    </NavLink>
                  </u>
                  , which also apply to use of our Services.
                </p>

                <ol>
                  <li>Information Collection</li>
                </ol>
                <p>
                  Information collected in connection with your use of
                  OPPORTUNITYPLATE may include:
                </p>
                <p>Information you provide, such as:</p>
                <ul>
                  <li>
                    Contact information, such as your first and last name, email
                    address, street address, and phone number.
                  </li>
                  <li>
                    Transaction information, such as discussions with contacts,
                    posting or editing resumes, and interacting with job
                    postings and/or recruiters.
                  </li>
                  <li>
                    Feedback or correspondence, such as information you provide
                    when you contact us with questions, feedback.
                  </li>
                  <li>
                    Other informationthat you provide to us voluntarily, or
                    which reflects our offline interactions with you.
                  </li>
                </ul>

                <ol start="2">
                  <li>
                    Cookies and Other Information Collected by Automated Means.
                  </li>
                </ol>
                <p>
                  We, our service providers, and other third parties may
                  automatically collect information about you, your computer or
                  mobile device, and activity occurring on or through the
                  OPPORTUNITYPLATE. These parties may collect this type of
                  information over time and across third-party applications,
                  websites, and/or services and may use cookies, web beacons,
                  and other technologies to collect this information. The
                  information may include:
                </p>
                <ul>
                  <li>
                    Information about your device’s hardware, such as model and
                    screen resolution
                  </li>
                  <li>
                    Information about the software on your device, such as your
                    browser type
                  </li>
                  <li>
                    Unique identifiers associated with your device, such as
                    device identifier (e.g., Google Advertising ID or Apple ID
                    for Advertising)
                  </li>
                  <li>
                    Information about the network to which you are connected and
                    inferences we may draw from it, such as your IP address and
                    general location information such as city, state or
                    geographic area
                  </li>
                  <li>
                    Information about your use of and actions on
                    OPPORTUNITYPLATE, such as items you viewed, how long you
                    spent on a certain item, navigation paths between items or
                    actions, information about your activity on
                    OPPORTUNITYPLATE, links you click, access times, and length
                    of access, if applicable
                  </li>
                </ul>
                <p>
                  Please see the “Targeted online advertising” section for
                  information about how to exercise choice regarding the use of
                  browsing behavior for purposes of targeted advertising.
                </p>
                <ol start="3">
                  <li>How We Use Personal Information</li>
                </ol>
                <p>
                  We use your personal information for the following purposes
                  and as otherwise described in this Privacy Policy or at the
                  time of collection:
                </p>
                <p>
                  <u>To operate OPPORTUNITYPLATE</u>. We use your personal
                  information to:
                </p>
                <ul>
                  <li>
                    Provide, operate, and improve OPPORTUNITYPLATE, including to
                    develop new products and services
                  </li>
                  <li>Provide the services you request</li>
                  <li>
                    Communicate with you about OPPORTUNITYPLATE, including by
                    sending you announcements, updates, security alerts, and
                    support and administrative messages
                  </li>
                  <li>
                    Personalize your experience with OPPORTUNITYPLATE and our
                    communications
                  </li>
                  <li>
                    Provide support and maintenance for OPPORTUNITYPLATE,
                    including by responding to your requests, questions, and
                    feedback
                  </li>
                </ul>
                <p>
                  <u>To send you marketing and promotional communications</u>.
                  Company may send you marketing communications. Please see the
                  “Your Choices” section below for information about your
                  choices regarding marketing communications.
                </p>
                <p>
                  <u>To display advertisements</u>. We work with advertising
                  partners to display Company’s advertisements online. These
                  advertisements are delivered by our advertising partners and
                  may be targeted based on your use of OPPORTUNITYPLATE or your
                  activity elsewhere online. To learn more about your choices in
                  connection with advertisements, please see the section below
                  titled “Targeted online advertising.”
                </p>
                <p>
                  <u>For compliance, fraud prevention, and safety</u>. We may
                  use your personal information and disclose it to law
                  enforcement, government authorities, and private parties as we
                  believe necessary or appropriate to: (a) comply with
                  applicable laws, lawful requests, and legal process, such as
                  to respond to subpoenas or requests from government
                  authorities; (b) protect our, your or others’ rights, privacy,
                  safety or property (including by making and defending legal
                  claims); (c) enforce the terms and conditions that govern the
                  Service; and (d) protect, investigate and deter against
                  fraudulent, harmful, unauthorized, unethical or illegal
                  activity.
                </p>

                <ol start="4">
                  <li>
                    How We Share Personal Information
                    <ol>
                      <li>
                        <u>Service Providers</u>. We may engage other companies
                        and individuals to perform certain business-related
                        functions on our behalf. Examples may include providing
                        technical assistance, payment processing, billing, email
                        management, customer service, marketing assistance,
                        order fulfillment, delivery services, and reservation
                        and online ordering systems. We authorize these parties
                        to use personal information only as necessary to perform
                        their functions.
                      </li>
                      <li>
                        <u>Business Transfers</u>. In connection with or during
                        the negotiation of a financing, merger, acquisition,
                        dissolution or similar corporate event, or the sale of
                        all or some of OPPORTUNITYPLATE’s or Company’s assets,
                        the information that OPPORTUNITYPLATE or Company, as
                        applicable, have collected, including personal
                        information, may be transferred as a business asset. If
                        the company, business, or assets are acquired by another
                        company, the acquiring company will possess the personal
                        information that OPPORTUNITYPLATE or Company, as
                        applicable, collected and will assume the rights and
                        obligations regarding your personal information as
                        described in this Privacy Policy.
                      </li>
                      <li>
                        <u>Third Parties Providing Services on Our Behalf</u>.
                        We use third parties to perform certain services on our
                        behalf, such as hosting the Site or Site features,
                        providing website usage analytics, fulfilling orders,
                        processing payments, sending email or other
                        communications, providing marketing assistance and data
                        analysis or enhancement, running promotions, or
                        performing other administrative services. We may share
                        information about you with these third parties to allow
                        them to execute, deliver, or improve the services that
                        they provide for you and for OPPORTUNITYPLATE.
                      </li>
                    </ol>
                  </li>
                </ol>
                <ul>
                  <li>
                    We utilize Google and Apple's in-app purchase services for
                    electronic payment processing on the Service. By utilizing
                    these payment services, you agree to be bound by Google and
                    Apple's terms and conditions, as well as their respective
                    privacy policies. You hereby consent and authorize us to
                    delegate the necessary authorizations and share the
                    information you provide with Google and Apple as required to
                    provide payment services to you through their platforms.
                  </li>
                </ul>

                <p>
                  (ii) We use Google Analytics. Google Analytics uses cookies
                  and similar technologies to collect and analyze information
                  about the use of the Website and report on activities and
                  trends. This service may also collect information regarding
                  the use of other websites, apps, and online resources. For
                  information from Google about opting out, click here, and to
                  download the Google Analytics opt-out browser add-on from
                  Google, click <u>here</u>.
                </p>

                <p>
                  T/C:{" "}
                  <NavLink to="https://www.google.com/analytics/terms/?hl=en-US">
                    https://www.google.com/analytics/terms/?hl=en-US
                  </NavLink>
                </p>
                <p>
                  Privacy:{" "}
                  <NavLink to="https://policies.google.com/privacy?hl=en-US">
                    https://policies.google.com/privacy?hl=en-US
                  </NavLink>
                </p>

                <ol>
                  <li>
                    <u>When You Request That We Share Your Information</u>. We
                    may share information when you request us to do so, for
                    example, if you opt-in to receive a communication from a
                    third party or if you engage with a Social Media Platform or
                    other third-party application or feature through which
                    information about you is shared.
                  </li>
                  <li>
                    <u>Legal Requirements</u>. We may disclose your information
                    if required to do so by law, or in the good faith belief
                    that such action or disclosure is necessary or appropriate
                    to: (i) operate OPPORTUNITYPLATE, (ii) comply with any legal
                    obligation, report unlawful activity, cooperate with law
                    enforcement or public authorities (including for the purpose
                    of meeting national security or law enforcement
                    requirements), (iii) protect against legal liability, (iv)
                    protect and defend our rights, property, personnel,
                    suppliers, sponsors, agents or licensors, (v) protect the
                    personal safety of vendors, users of OPPORTUNITYPLATE or the
                    public, or (vi) comply when compelled to disclose personal
                    information to other third parties by government authorities
                    or as required by law or regulation, including, but not
                    limited to, in response to court orders and subpoenas.
                  </li>
                  <li>
                    <u>Business Transfers.</u> In evaluating or engaging in a
                    sale of assets, merger, acquisition, reorganization,
                    bankruptcy, or other transaction, we reserve the right to
                    disclose, transfer, or assign your personal information and
                    communications. By providing your personal information, you
                    agree that we may transfer such information to the acquiring
                    entity without your further consent.
                  </li>
                </ol>
                <p>
                  <u></u>
                </p>
                <ol start="5">
                  <li>
                    Your Choices
                    <ol>
                      <li>
                        <u>Opt-out of Marketing Communications</u>. You may opt
                        out of marketing communications from Company by
                        following such instructions as may be provided in the
                        communication, or by changing your opt-out options
                        through the App.
                      </li>
                      <li>
                        <u>Cookies &amp; Browser Web Storage</u>. We may allow
                        service providers and other third parties to use cookies
                        and similar technologies to track your browsing activity
                        over time and across OPPORTUNITYPLATE and third-party
                        services. Most browsers let you remove or reject
                        cookies. To do this, follow the instructions in your
                        browser settings. Many browsers accept cookies by
                        default until you change your settings. Please note that
                        if you set your browser to disable cookies,
                        OPPORTUNITYPLATE may not work properly. Similarly, your
                        browser settings may allow you to clear your browser web
                        storage.{" "}
                      </li>
                      <li>
                        <u>Targeted Online Advertising</u>. Some of the third
                        parties that collect information about users’ activities
                        on or through OPPORTUNITYPLATE may be members of
                        organizations or programs that provide choices to
                        individuals regarding the use of their browsing behavior
                        or mobile application usage for purposes of targeted
                        advertising. Users may opt out of receiving targeted
                        advertising through members of the Network Advertising
                        Initiative by clicking here or the Digital Advertising
                        Alliance by clicking here. Please note that we also may
                        work with companies that offer their own opt-out
                        mechanisms and may not participate in the opt-out
                        mechanisms that we linked above.
                      </li>
                    </ol>
                  </li>
                </ol>
                <p>
                  If you choose to opt-out of targeted advertisements, you will
                  still see advertisements online but they may not be relevant
                  to you. Even if you do choose to opt out, not all companies
                  that serve online behavioral advertising are included in this
                  list, so you may still receive some cookies and tailored
                  advertisements from companies that are not listed.
                </p>
                <ol>
                  <li>
                    <u>Do Not Track</u>. Some Internet browsers may be
                    configured to send “Do Not Track” signals to visited
                    Websites. Some services do not currently monitor, recognize,
                    or honor any opt-out or do not track mechanisms, including
                    general web browser “Do Not Track” settings and/or signals,
                    in connection with OPPORTUNITYPLATE.
                  </li>
                </ol>

                <ol start="6">
                  <li>Accessing Personal Information and Retention Period</li>
                </ol>
                <p>
                  (a) <u>Accessing Your Account</u>. Please note that if you
                  choose not to provide us with certain personal information, we
                  may not always be able to provide you with the relevant
                  OPPORTUNITYPLATE product or service that you request. For
                  example, without providing an email address, you will not be
                  able to create an account.
                </p>
                <ul>
                  <li>
                    You may access, view, correct, or update certain personal
                    information collected about you by accessing your account
                    through the OPPORTUNITYPLATE application and edit your
                    personal information. If you want to deactivate your
                    account, you may do so through your account.
                  </li>
                  <li>
                    If you have any concerns about how we are using your
                    personal information, would like to correct or remove
                    correct the personal information that you have provided to
                    us, please send your request using the contact details in
                    the Contact Us section.
                  </li>
                </ul>
                <p>
                  (b) <u>Retention Period</u>. When you request that we delete
                  your personal information, we will take commercially
                  reasonable steps to remove it from our active databases but
                  will keep original information consistent with our business
                  practices to the extent permitted by applicable law (for
                  example, for purposes of dispute resolution, enforcement of
                  agreements, complying with legal requirements, recordkeeping,
                  backup, and document retention).
                </p>

                <ol start="7">
                  <li>How We Protect Your Information</li>
                </ol>
                <p>
                  OPPORTUNITYPLATE maintains commercially reasonable measures
                  designed to protect your personal information in connection
                  with your use of OPPORTUNITYPLATE. However, no security system
                  is impenetrable, and we cannot guarantee the security of your
                  personal information.
                </p>
                <ul>
                  <li>
                    <u>External Websites</u>. OPPORTUNITYPLATE may contain links
                    to other Websites and online services operated by third
                    parties, which are not associated with Company and which
                    Company does not control. We are not responsible for the
                    content or the privacy policies or practices of those
                    Websites or online services. We encourage you to read the
                    privacy policy and legal terms of Websites or online
                    services that you visit or use.
                  </li>
                  <li>
                    <u>Contact Us</u>. If you have questions about this Privacy
                    Policy, please contact Company using the contact information
                    provided on OPPORTUNITYPLATE.
                  </li>
                  <li>
                    <u>Changes to This Privacy Policy</u>. This Privacy Policy
                    is effective as of the date stated at the top of this
                    Privacy Policy. We may change this Privacy Policy from time
                    to time. Any such changes will be posted on
                    OPPORTUNITYPLATE. If we make material changes, we will
                    notify you by posting an announcement on OPPORTUNITYPLATE or
                    sending you an email.
                  </li>
                </ul>

                <p>
                  <strong>
                    <u>ADDITIONAL INFORMATION FOR CALIFORNIA RESIDENTS</u>
                  </strong>
                </p>

                <p>
                  This section applies only if Company’s privacy practices are
                  governed by The California Consumer Privacy Act of 2018
                  (“CCPA”), and only with respect to .
                </p>
                <p>
                  The California Consumer Privacy Act of 2018 (“CCPA”) requires
                  Company to provide to California residents an explanation of
                  how Company collects, uses, and shares their personal
                  information, and of the rights and choices Company offers to
                  California residents regarding its handling of personal
                  information.{" "}
                </p>
                <p>
                  {/* <em> */}
                    Personal Information That We Collect, Use, and Share.{" "}
                  {/* </em> */}
                  Company does not sell personal information. As explained in
                  this Privacy Policy, OPPORTUNITYPLATE uses cookies and other
                  tracking tools to analyze traffic and facilitate advertising.
                  If you would like to opt out of OPPORTUNITYPLATE’s (and
                  third-party advertising partners’) use of cookies and other
                  tracking technologies, please review the instructions provided
                  in the{" "}
                  <u>
                    <a href="#otg">Online Tracking Opt-out Guide</a>
                  </u>
                  .
                </p>

                <p>
                  Here is a summary of how we currently collect, use and share
                  personal information, and how we have collected used and
                  shared the information in the preceding 12 months.
                </p>

                <div className="table table-responsive">
                <table className="table table-bordered">
                  <tbody>
                    <tr>
                      <td>
                        <p>Personal information we collect</p>
                      </td>
                      <td>
                        <p>
                          CCPA categories (
                          <u>
                            <a href="#CCPA">Definitions are available here</a>
                          </u>
                          )
                        </p>
                      </td>
                      <td width="146">
                        <p>Sources of personal information</p>
                      </td>
                      <td width="192">
                        <p>
                          Purposes for which we may collect and use personal
                          information
                        </p>
                      </td>
                      <td width="150">
                        <p>Data Sharing</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>Contact information</li>
                          <li>Transaction information</li>
                          <li>Payment details</li>
                          <li>Your correspondence or feedback</li>
                          <li>
                            Other information that you provide to us voluntarily
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Identifiers</li>
                          <li>Commercial information</li>
                          <li>Financial Information</li>
                        </ul>
                      </td>
                      <td width="146">
                        <ul>
                          <li>You</li>
                        </ul>
                      </td>
                      <td width="192">
                        <ul>
                          <li>Service delivery</li>
                          <li>Marketing</li>
                          <li>Compliance &amp; Operations</li>
                        </ul>
                      </td>
                      <td width="150">
                        <ul>
                          <li>Shared with service providers</li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <ul>
                          <li>
                            Cookies and other information collected by automated
                            means
                          </li>
                          <li>
                            Inferences drawn from device data and online
                            activity data
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li>Unique Identifiers</li>
                          <li>Commercial Information</li>
                          <li>Internet or Network Information</li>
                          <li>Inferences</li>
                        </ul>
                      </td>
                      <td width="146">
                        <ul>
                          <li>You</li>
                          <li>Automatic collection</li>
                        </ul>
                      </td>
                      <td width="192">
                        <ul>
                          <li>Service delivery</li>
                          <li>Marketing</li>
                          <li>Compliance &amp; Operations</li>
                        </ul>
                      </td>
                      <td width="150">
                        <ul>
                          <li>Collected directly by advertising partners</li>
                          <li>
                            Shared with and collected directly by service
                            providers
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
                </div>

                <p>
                  Please note that we may also disclose personal information to
                  comply with law, and for compliance, fraud prevention, and
                  safety purposes; in the event of business transfers; and to
                  our professional advisors, as further described above in the
                  “How We Share Personal Information” section.
                </p>

                <p>
                  <strong>
                    <em></em>
                  </strong>
                </p>
                <p id="CCPA">
                  <strong>
                    California Residents’ Privacy Rights
                  </strong>
                </p>
                <p>
                  The CCPA grants California residents the following rights:
                </p>
                <ul>
                  <li>
                    <u>Information</u>. You can request information about how
                    Company has collected, used and shared your personal
                    information during the past 12 months.
                  </li>
                  <li>
                    <u>Access</u>. You can request a copy of the personal
                    information that Company maintain about you.
                  </li>
                  <li>
                    <u>Deletion</u>. You can ask Company to delete the personal
                    information that Company has collected or maintain about
                    you.
                  </li>
                </ul>
                <p>
                  Please note that the CCPA limits these rights by, for example,
                  prohibiting Company from providing certain sensitive
                  information in response to an access request and limiting the
                  circumstances in which Company must comply with a deletion
                  request. If Company denies your request, it will communicate
                  its decision to you.
                </p>
                <p>
                  You are entitled to exercise the rights described above free
                  from discrimination.
                </p>

                <p>
                  <u>How to make a request</u>. To request access to or deletion
                  of personal information, contact the OPPORTUNITYPLATE via the
                  contact information provided on OPPORTUNITYPLATE.
                </p>

                <p>
                  <u>Identity verification</u>. The CCPA requires
                  OPPORTUNITYPLATE to verify the identity of the individual
                  submitting the request before providing a substantive response
                  to the request.
                </p>

                <p>
                  <u>Authorized agents</u>. California residents can empower an
                  “authorized agent” to submit requests on their behalf. Company
                  will require the authorized agent to have a written
                  authorization confirming that authority.
                </p>

                <p>[Definitions link]</p>
                <p>
                  The CCPA defines “personal information” as “information that
                  identifies, relates to, describes, is capable of being
                  associated with, or could reasonably be linked, directly or
                  indirectly, with a particular consumer or household.”
                </p>
                <p>
                  It establishes the following categories of personal
                  information:
                </p>
                <ol>
                  <li>
                    <strong>Identifiers:</strong>Name, alias, postal address,
                    unique personal identifier, online identifier, Internet
                    Protocol (IP) address, email address, account name, social
                    security number, driver’s license number, passport number,
                    or other similar identifiers
                  </li>
                  <li>
                    <strong>Customer records information:</strong>Name,
                    signature, social security number, physical characteristics
                    or description, address, telephone number, passport number,
                    driver’s license or state identification card number,
                    insurance policy number, education, employment, employment
                    history, bank account number, credit or debit card number,
                    other financial information, medical information, health
                    insurance information
                  </li>
                  <li>
                    <strong>
                      Characteristics of protected classifications under
                      California or federal law:
                    </strong>
                    Race, religion, sexual orientation, gender identity, gender
                    expression, age
                  </li>
                  <li>
                    <strong>Commercial information:</strong>Records of personal
                    property, products or services purchased, obtained, or
                    considered, or other purchasing or consuming histories or
                    tendencies
                  </li>
                  <li>
                    <strong>Biometric information:</strong>Hair color, eye
                    color, fingerprints, height, retina scans, facial
                    recognition, voice, and other biometric data
                  </li>
                  <li>
                    <strong>
                      Internet or other electronic network activity information:
                    </strong>
                    Browsing history, search history, and information regarding
                    a consumer’s interaction with an Internet Website,
                    application, or advertisement
                  </li>
                  <li>
                    <strong>Geolocation data</strong>
                  </li>
                  <li>
                    <strong>
                      Audio, electronic, visual, thermal, olfactory, or similar
                      information
                    </strong>
                  </li>
                  <li>
                    <strong>
                      Professional or employment-related information
                    </strong>
                  </li>
                  <li>
                    <strong>Education information:</strong>Information that is
                    not “publicly available personally identifiable information”
                    as defined in the California Family Educational Rights and
                    Privacy Act (20 U.S.C. section 1232g, 34 C.F.R. Part 99)
                  </li>
                  <li>
                    <strong>Inferences</strong>. The law also includes
                    inferences that could be used to create a profile reflecting
                    a consumer’s:
                  </li>
                </ol>
                <ul>
                  <li>Preferences</li>
                  <li>Characteristics</li>
                  <li>Psychological trends</li>
                  <li>Predispositions</li>
                  <li>Behavior</li>
                  <li>Attitudes</li>
                  <li>Intelligence</li>
                  <li>Abilities</li>
                  <li>Aptitudes</li>
                </ul>
                <p>
                  It gives the California attorney general the power to add
                  categories of personal information to address changes in
                  technology, data collection practices, obstacles to
                  implementation, and privacy concerns.
                </p>
                <p>
                  <strong>What isn’t personal information</strong>
                </p>
                <p>
                  The CCPA’s definition of personal information does not include
                  publicly available information. That would be data contained
                  in publicly available federal, state, or local government
                  records.
                </p>
                <p>
                  Certain types of information are exempt from CCPA regulation,
                  including certain financial information and medical
                  information regulated by the Health Information Portability
                  and Accountability Act (HIPAA).
                </p>
                <p id="otg">[Online Tracking Guide/Opt-Out]</p>
                <p>Online Tracking Opt-Out Guide</p>
                <p>
                  This guide is not exhaustive and your usage of any and all
                  information herein is solely at your own risk.
                </p>
                <p>
                  Like many companies online, we may use services provided by
                  Google and other companies that use tracking technology. These
                  services rely on tracking technologies – such as cookies and
                  web beacons – to collect directly from your device information
                  about your browsing activities, your interactions with
                  Websites, and the device you are using to connect to the
                  Internet. There are a number of ways to opt out of having your
                  online activity and device data collected through these
                  services, which we have summarized below:
                </p>
                <p>
                  Blocking cookies in your browser. Most browsers let you remove
                  or reject cookies, including cookies used for interest-based
                  advertising. To do this, follow the instructions in your
                  browser settings. Many browsers accept cookies by default
                  until you change your settings. For more information about
                  cookies, including how to see what cookies have been set on
                  your device and how to manage and delete them, visit
                  www.allaboutcookies.org.
                </p>
                <p>
                  Blocking advertising ID use in your mobile settings. Your
                  mobile device settings may provide functionality to limit use
                  of the advertising ID associated with your mobile device for
                  interest-based advertising purposes.
                </p>
                <p>
                  Using privacy plug-ins or browsers. You can block our Services
                  from setting cookies used for interest-based ads by using a
                  browser with privacy features, like Brave, or installing
                  browser plugins like Privacy Badger, Ghostery or uBlock
                  Origin, and configuring them to block third party
                  cookies/trackers.
                </p>
                <p>
                  Platform opt-outs. The following advertising partners offer
                  opt-out features that let you opt-out of use of your
                  information for interest-based advertising:
                </p>
                <ul>
                  <li>
                    Google:{" "}
                    <NavLink to="https://adssettings.google.com">
                      https://adssettings.google.com
                    </NavLink>
                  </li>
                </ul>

                <p>
                  Advertising industry opt-out tools. You can also use these
                  opt-out options to limit use of your information for
                  interest-based advertising by participating companies:
                </p>

                <ul>
                  <li>
                    Digital advertising Alliance:{" "}
                    <NavLink to="http://optout.aboutads.info">
                      http://optout.aboutads.info
                    </NavLink>
                  </li>
                  <li>
                    Network Advertising Initiative:{" "}
                    <NavLink to="http://optout.networkadvertising.org/?c=1">
                      http://optout.networkadvertising.org/?c=1
                    </NavLink>
                  </li>
                </ul>
                <p>
                  Note that because these opt-out mechanisms are specific to the
                  device or browser on which they are exercised, you will need
                  to opt-out on every browser and device that you use. Any use
                  of this guide is solely at the discretion of the user.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Privacy;
