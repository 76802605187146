import "./style.css";
import Router from "./router/Router";
import { BrowserRouter } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <div className="wrapper">
     
      <BrowserRouter>
      <Header />
        <Router />
      <Footer />
      </BrowserRouter>
      
    </div>
  );
}

export default App;
