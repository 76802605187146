import React from 'react';

const JobPostingStrategies = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Effective Job Posting Strategies</h1>

      <h6 style={styles.subHeader}>Clear and Descriptive Job Titles</h6>
      <p style={styles.paragraph}>
        Choose job titles that are clear and accurately represent the position and its duties. Steer clear of
        jargon or internal terms that may be unfamiliar to job seekers.
      </p>

      <h6 style={styles.subHeader}>Comprehensive Job Descriptions</h6>
      <p style={styles.paragraph}>
        Give a thorough explanation of the job, covering responsibilities, required qualifications, skills,
        and any specific needs. Detail the role to attract candidates who are genuinely suited to the
        position.
      </p>

      <h6 style={styles.subHeader}>Use Bullet Points</h6>
      <p style={styles.paragraph}>
        Structure job requirements and qualifications with bullet points for easy readability and quick
        comprehension.
      </p>

      <h6 style={styles.subHeader}>Highlight Company Culture and Values</h6>
      <p style={styles.paragraph}>
        Share information about your company's culture, values, and unique benefits or perks. This helps
        potential candidates understand the work environment and attracts those who resonate with your
        company's ethos.
      </p>

      <h6 style={styles.subHeader}>Optimize for Search Engines</h6>
      <p style={styles.paragraph}>
        Integrate relevant keywords and phrases to boost the job posting’s visibility in search engine
        results, helping you reach a larger pool of qualified candidates.
      </p>

      <h6 style={styles.subHeader}>Include Salary and Benefits Information</h6>
      <p style={styles.paragraph}>
        Be upfront about the salary range and provide details on benefits like healthcare, retirement
        plans, and paid leave. Transparency in compensation attracts candidates who are well-matched to
        the role.
      </p>

      <h6 style={styles.subHeader}>Clear Application Instructions</h6>
      <p style={styles.paragraph}>
        Clearly outline the application process, including any specific documents or information needed.
        Provide contact information or guidelines for submitting applications online.
      </p>

      <h6 style={styles.subHeader}>Mobile-Friendly Format</h6>
      <p style={styles.paragraph}>
        Ensure your job posting is optimized for mobile devices, as many job seekers use smartphones
        and tablets. A mobile-friendly format improves the user experience and increases application
        rates.
      </p>

      <h6 style={styles.subHeader}>Engaging Language</h6>
      <p style={styles.paragraph}>
        Craft your job posting with engaging and attractive language to capture the attention of your
        target audience. Emphasize the benefits of the role and your company to attract interest.
      </p>

      <h6 style={styles.subHeader}>Proofread Carefully</h6>
      <p style={styles.paragraph}>
        Thoroughly proofread your job posting to catch any spelling or grammatical errors. A well-
        written, error-free posting reflects well on your organization and helps attract high-quality
        candidates.
      </p>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    maxWidth: '800px',
    margin: '0 auto',
    color: '#333',
  },
  header: {
    color: '#2c3e50',
    fontSize: '2.5em',
    marginBottom: '20px',
  },
  subHeader: {
    color: '#34495e',
    fontSize: '1.5em',
    marginTop: '20px',
    marginBottom: '10px',
  },
  paragraph: {
    marginBottom: '20px',
  },
  boldText: {
    fontWeight: 'bold',
  },
  list: {
    paddingLeft: '20px',
    marginBottom: '20px',
    listStyleType: 'none',
  },
  listItem: {
    marginBottom: '5px',
  },
};

export default JobPostingStrategies;
