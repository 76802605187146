import React, { useState } from 'react'

const Contact = () => {

	const [formType, setFormType] = useState('recruiter')
	const handleFormType = (e) => {
		const { value } = e.target
		setFormType(value)
	}

	const initialState = {
		name: '',
		email: '',
		company: '',
		mobile: '',
		message: ''
	}

	const [values, setValues] = useState(initialState)

	const handleChange = (e) => {
		const { name, value } = e.target
		setValues({ ...values, [name]: value })
	}

	const { name, email, company, mobile, message } = values

	const handleSubmit = (e) => {
		e.preventDefault()
		console.log(values)
		postMail({ role: formType, ...values })
			.then((data) => {
				if (data) setValues(initialState)
			})
			.catch((err) => console.log(err))
	}

	const postMail = async (body) => {
		const endpoint = "https://api.oppplate.com/mail"
		try {
			const res = await fetch(
				endpoint,
				{
					method: "POST",
					headers: {
						'Content-type': 'application/json'
					},
					body: body
				});
			const data = res.json();
			return data
		} catch (error) {
			if (error) return error
		}
	}

	return (
		<div>
			<div className="page-bnr">
		<h1 className="fw-bold mb-0 text-center">Contact us</h1>
		</div>
			<section className="inner-page-wrapper py-5">
				<div className="container">
					<div className="row d-flex justify-content-center">
						<div className="col-md-10 col-lg-8 ">
							<div className="contact-form py-4">
								<form onSubmit={handleSubmit}>
									<div className="row">
										<div className="col-lg-12">
											<div className="form-group custom-radio-box">
												<div className="custom-control custom-radio">
													<input type="radio" className="custom-control-input" id="recruiter" name="contactType" value="recruiter" onChange={handleFormType} checked={formType === "recruiter"} />
													<label className="custom-control-label" htmlFor="recruiter" id="recruiterLabel">Recruiter</label>
												</div>
												<div className="custom-control custom-radio">
													<input type="radio" className="custom-control-input" id="jobseeker" name="contactType" value="jobseeker" onChange={handleFormType} checked={formType === "jobseeker"} />
													<label className="custom-control-label" htmlFor="jobseeker" id="jobseekerLabel">Jobseeker</label>
												</div>
											</div>
										</div>

										{/* HERE */}
										{/* <div className="col-md-12">
											<div className="alert alert-success mb-3 d-flex align-items-center" role="alert">
												<i className="fas fa-check pe-2"></i>
												<div>
													Our team will get in touch with you soon...
												</div>
											</div>
										</div>
										<div className="col-md-12">
											<div className="alert alert-danger mb-3 d-flex align-items-center" role="alert">
												<i className="fas fa-times pe-2"></i>
												<div>
													Something went wrong!
												</div>
											</div>
										</div> */}
										{/* HERE */}

										<div className={formType === "recruiter" ? "col-sm-6" : "col-sm-12"}>
											<div className="mb-4">
												<input type="text" className="form-control" name="name" id="name" placeholder="Name*" onChange={handleChange} value={name} required />
											</div>
										</div>
										{formType === "recruiter" && <div className={`col-sm-6 col-md-6 col-lg-6 recruiterField `}>
											<div className="mb-4">
												<input type="text" className="form-control" name="company" id="company" placeholder="Company*" onChange={handleChange} value={company} />
											</div>
										</div>}
										<div className="col-sm-6 col-md-6 col-lg-6">
											<div className="mb-4">
												<input type="text" className="form-control" name="mobile" id="phone" placeholder="Phone" maxLength="10" onInput={(e) => { setValues({ ...values, mobile: values.mobile.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1') }) }} onChange={handleChange} value={mobile} required />
											</div>
										</div>
										<div className="col-sm-6 col-md-6 col-lg-6">
											<div className="mb-4">
												<input type="email" className="form-control" name="email" id="email" placeholder="Email*" required onChange={handleChange} value={email} />
											</div>
										</div>
										<div className="col-sm-12 col-md-12 col-lg-12">
											<div className="mb-4">
												<textarea className="form-control" placeholder="Message" name="message" id="messagetext" onChange={handleChange} value={message} ></textarea>
											</div>
										</div>
										<div className="col-sm-12 col-md-12 col-lg-12 text-center">
											<input className="btn w-100 btn-custom btn-submit" type="submit" value="Submit" id="btn_submit" />
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}

export default Contact