import React, { useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Header = () => {
  const location = useLocation();
  const stickyRef = useRef(null);
  const findIfScrolled = () => {
    window.onscroll = function (e) {
      if (window.scrollY > 0)
        stickyRef?.current?.classList.add("sticky");
      else stickyRef?.current?.classList.remove("sticky");
    };
  };

  useEffect(() => {
    findIfScrolled();
  }, []);

  return (
    <div id="mainSection" ref={stickyRef}>
      <header
        id="myHeader"
        className={location.pathname !== "/" ? "inner-header" : ""}
      >
        <nav className="navbar navbar-expand-sm custom-navbar">
          <div className="container">
            <NavLink className="navbar-brand py-0" to="/">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
                className="img-fluid"
                alt="logo"
              />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="collapsibleNavbar">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink className="nav-link" to="/">
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/About">
                    About us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="/Contact">
                    Contact us
                  </NavLink>
                </li>
              </ul>
              <div className="social-media d-flex justify-content-center align-items-center px-3 py-2 rounded-pill shadow-sm" style={{backgroundColor: "rgb(255 255 255 / 36%)", marginTop: "-9px"}}>
								{/* <h6 className="mb-0 pe-2 me-2 border-end border-dark small">Follow Us</h6> */}
								<ul>
									<li>
										<NavLink to="https://www.facebook.com/profile.php?id=61551910187686&mibextid=LQQJ4d" target="_blank">
											<i className="fab fa-facebook-f"></i>
										</NavLink>
									</li>
									<li>
										<NavLink to="https://www.instagram.com/opportunity.plate" target="_blank">
											<i className="fab fa-instagram"></i>
										</NavLink>
									</li>
									<li>
										<NavLink to="https://www.linkedin.com/company/opportunityplate/" target="_blank">
											<i className="fab fa-linkedin-in"></i>
										</NavLink>
									</li> 
								</ul>
							</div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
