import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import ContactPage from "../pages/ContactPage";
import CandidatesPage from "../pages/CandidatesPage";
import RecruiterPage from "../pages/RecruiterPage";
import PrivacyPage from "../pages/PrivacyPage";
import TermsPage from "../pages/TermsPage";
import JobPostingGuidelines from "../pages/ProhibitedWordsPage";
import JobPostingStrategies from "../pages/BestPractices";

const Router = () => {
  return (
    <Routes>
      <Route path="/" index element={<HomePage />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/candidates" element={<CandidatesPage />} />
      <Route path="/recruiters" element={<RecruiterPage />} />
      <Route path="/privacy" element={<PrivacyPage />} />
      <Route path="/terms" element={<TermsPage />} />
      <Route path="/phrases" element={<JobPostingGuidelines />} />
      <Route path="/best-practices" element={<JobPostingStrategies />} />
    </Routes>
  );
};

export default Router;
