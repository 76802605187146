import React from 'react'

const About = () => {
  return (
    <div>
		<div className="page-bnr">
		<h1 className="fw-bold mb-0 text-center">About us</h1>
		</div>
      <section className="about-us-wrapper py-5">
		<div className="container">
			<div className="row">
				<div className="col-sm-12 col-md-12 col-lg-6">
					<div className="about-us-content">
						<h2 className="mb-3">The recruitment industries biggest challenges have been communication gaps and candidate
							experiences. </h2>
						<p>Opportunity Plate bridges the gap and creates a seamless experience for both recruiters and
							candidates. The thought behind Opportunity Plate came from extensive experience and
							knowledge in
							the recruiting industry. Based on inputs from a veteran in the IT staffing and recruiting
							space
							with over two decades of experience in full life-cycle technical and non-technical
							recruiting in
							direct-hire, permanent and contract positions in United States. The recruiter - candidate
							relationship is crucial to the hiring process and agility of an organization at managing
							resources. </p>
						<p>We saw a disconnect in the process that didn't allow either to have a fulfilling experience.
							Candidates are often left wondering on the status of their applications and recruiters are
							constantly under pressure to find the right fit. Negative candidate experience is prevalent
							across all job categories. 75% of candidates are ghosted either before or after an interview and recruiters
							don't have the right tools to manage the full lifecycle of the candidate interview.</p>
					</div>
				</div>
				<div className="col-sm-12 col-md-12 col-lg-6">
					<div className="about-us-image">
						<img src={process.env.PUBLIC_URL + "/assets/images/image-05.jpg"} className="img-fluid" alt=""/>
					</div>
				</div>
			</div>
			<div className="row mt-5">
				<div className="col-sm-12 col-md-12 col-lg-6 order-lg-2">
					<div className="about-us-content ps-3">
						<p>Opportunity Plate is an industry agnostic solution to close the gap between job seekers and
							recruiters, the Opportunity Plate App is an interactive approach that directly links the two
							on a networking platform. The app is a conduit that enables candidate to reach out to
							recruiters directly and vice versa. They can network, build rapport, and post updates on the
							current job opportunities and employment status. Our endeavour is to create an easy-to-use
							virtual rolodex of recruiters and candidates. Direct communication creates a real connection
							that last beyond a job opportunity. While identifying the right fit is crucial for hiring
							and growth, we also wanted to create a space for recruiters to schedule, update and guide
							the candidates through the interview process. The candidates post their employment status
							and allow the recruiters to see when they are available to be hired or are looking for a
							change.</p>
						<p>The Opportunity Plate app puts recruitment process mapping as top priority and improves the
							candidate experience exponentially. It enables the recruiters to be hyper focused on
							creating an efficient recruiting process and attract, manage, and hire the right talent.</p>
					</div>
				</div>
				<div className="col-sm-12 col-md-12 col-lg-6">
					<div className="about-us-image">
						<img src={process.env.PUBLIC_URL + "/assets/images/image-06.jpg"} className="img-fluid" alt=""/>
					</div>
				</div>
			</div>
		</div>
	</section>
    </div>
  )
}

export default About